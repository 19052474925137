
export default {
  props: {
    icon: {
      type: String,
      default: null, // it does not render the icon if the default value is defined here when icon props is null
    },
  },

  computed: {
    defaultIcon() {
      return this.icon
        ? this.icon
        : '<span class="material-icons-outlined">rocket_launch</span>'
    },
  },
}
