import { render, staticRenderFns } from "./TeaserSmall.vue?vue&type=template&id=7d94fa18&scoped=true"
import script from "./TeaserSmall.vue?vue&type=script&lang=js"
export * from "./TeaserSmall.vue?vue&type=script&lang=js"
import style0 from "./TeaserSmall.vue?vue&type=style&index=0&id=7d94fa18&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d94fa18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MaterialIcon: require('/vercel/path0/components/general/MaterialIcon.vue').default,AppButton: require('/vercel/path0/components/general/AppButton.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
