
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hover: null,
      inLivePreview: false,
    }
  },

  mounted() {
    this.checkIfIsInLivePreview()

    this.initAnimation()
  },

  methods: {
    initAnimation() {
      if (this.$route.query['live-preview']) {
        return
      }

      const breakpoint = 1024

      // mobile
      this.$gsap
        .matchMedia()
        .add(`(max-width: ${breakpoint - 1}px)`, () => this.mobileAnimation())

      // desktop
      this.$gsap
        .matchMedia()
        .add(`(min-width: ${breakpoint}px)`, () => this.desktopAnimation())
    },

    mobileAnimation() {
      this.$gsap.context(() => {
        this.$ScrollTrigger.batch('.fade-in-clipped', {
          start: 'top 96%',
          markers: false,

          onEnter: (elements) => {
            this.$gsap.to(elements, {
              duration: 0.6,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
              stagger: 0.15,
            })
          },
        })

        this.$ScrollTrigger.batch('.slide-up', {
          start: 'top 96%',
          markers: false,

          onEnter: (elements) => {
            this.$gsap.to(elements, {
              duration: 0.6,
              y: 0,
              opacity: 1,
              stagger: 0.07,
            })
          },
        })
      }, this.$el)
    },

    desktopAnimation() {
      this.$gsap.context(() => {
        this.$ScrollTrigger.batch('.fade-in-clipped', {
          start: 'top 75%',
          markers: false,

          onEnter: (elements) => {
            this.$gsap.to(elements, {
              duration: 0.6,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
              stagger: 0.15,
            })
          },
        })

        this.$ScrollTrigger.batch('.slide-up', {
          start: 'top 75%',
          markers: false,

          onEnter: (elements) => {
            this.$gsap.to(elements, {
              duration: 0.6,
              y: 0,
              opacity: 1,
              stagger: 0.07,
            })
          },
        })
      }, this.$el)
    },

    checkIfIsInLivePreview() {
      this.inLivePreview = !!this.$route.query['live-preview']
    },
  },
}
